<template>
    <div class="v2-term">
        <div class="title">우주메리 이용 약관</div>
        <div class="contents">
            <div class="content-item m-b-16" v-for="term in terms" :key="term.title">
                <p>{{ term.title }}</p>
                <div class="content">
                    <p v-if="typeof term.content === 'string'">{{ term.content }}</p>
                    <template v-else>
                        <div v-for="item in term.content" :key="item.content">
                            <div class="item">
                                <div class="num" v-html="`${item.id}. `" />
                                <div class="content" v-html="item.content" />
                            </div>
                            <div v-if="item.specInfos" class="indent">
                                <div class="item" v-for="s in item.specInfos" :key="s.content">
                                    <div class="num" :class="{ 'period-dot': s.id !== '-' }">{{ s.id }}</div>
                                    <div class="content">{{ s.content }}</div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContractTermV2',
    props: {
        contractVersion: Object,
    },
    computed: {
        terms() {
            return this.contractVersion.terms
        },
    },
}
</script>

<style scoped lang="scss">
.v2-term {
    padding-bottom: 40px;
    border-bottom: 1px solid $grey-03;
    margin-bottom: 40px;

    .indent {
        margin-left: 16px;
    }
    .title {
        text-align: center;
        margin-bottom: 12px;
        font-size: 18px;
        @include spoqa-f-bold;
    }
    .item {
        display: flex;
        .num {
            width: 5%;
            &.period-dot::after {
                content: '.';
            }
        }
        .content {
            width: 90%;
        }
    }
}
</style>
